<template >
<div class="login-container">
  <el-form class="login-form" ref='loginForm' :model='loginForm' :rules='loginRules' auto-complete='on' label-position='left'>
    <div class="title-container">
      <h5 title> Представьтесь, пожалуйста </h5>
    </div>
    <el-form-item>
      <el-autocomplete style="width: 100%;"
        v-model='loginForm.username_link'
        @select="handleSelect" @focus="loginForm.username_link=''"
        placeholder="Начинайте набирать логин "
        :fetch-suggestions="querySearch"
        :trigger-on-focus="false"
      ></el-autocomplete>
      <!-- (Предназначено для облегчения выбора логина) -->
    </el-form-item>
    <!-- <el-form-item class="inp" prop='username'>
      <el-input ref='username' v-model='loginForm.username' placeholder='Ваш логин' name='username' type='text' 
        tabindex='1' auto-complete='on'/>
    </el-form-item> -->
    <el-form-item class="inp" prop='password'>
      <!-- - var attr = {'key':'passwordType', 'ref':'password', 'v-model':'loginForm.password',
      - 'type':'password', 'placeholder':'Пароль', 'name':'password', 'tabindex':'2',
      - 'auto-complete':'on', '@keyup.enter.native':'handleLogin'} -->
      <el-input show-password key='passwordType' ref='password' v-model='loginForm.password' type='password' 
        placeholder='Пароль' name='password' tabindex='2' @keyup.enter='handleLogin'/>
    </el-form-item>
    <el-button class="butt" :loading='loading' type='primary' @click.prevent='handleLogin'><span class="buttxt"> Вход </span></el-button>
    <!-- <el-button class="butt" type='primary' plain @click.prevent='handleRegister'> Регистрация </el-button> -->
    <!-- el-button.butt(type="text" style="margin-bottom:5px;" @click="freeLogin()") Вход без регистрации (Гость) -->

  </el-form>
</div>
</template>

<script>
import axios from 'axios'
import { hostus } from "../utils/func.js";
import { validUsername } from '@/utils/validate'

export default {
  name: 'Login',
  props: ["objfn"],
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!validUsername(value)) {
        callback(new Error('Введите корректное значение. Должно быть от 7 до 20 символов'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 7) {
        callback(new Error('Должно быть не менее 7 символов'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: '',
        username_link: '',
        password: '',
        passwnb: ''
      },
      showPasswnb: false,
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      loading: false,
      options: [],
      //passwordType: 'password',
      
    }
  },
  mounted: async function () { 
    let result = await axios.post(`${hostus}/mysqlus/select`, { username: '*' })
    
    result.data.forEach(el => {
      let user = {}
      user.link = el.username
      user.value = `${el.name_f} ${el.name_i} ${el.name_o}`
      this.options.push(user)
    });
    //console.log('this.options=', this.options)
  },
  /* watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  }, */
  methods: {
    checkb() { this.loginForm.passwnb = '' },
    handleLogin() { 
      //console.log('this.loginForm=',this.loginForm);
      if (this.loginForm.username == '') this.loginForm.username = this.loginForm.username_link
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/login', this.loginForm).then((msg) => {
            if (msg != "") { 
              this.$alert(msg, { title: 'Ошибка входа', type: 'error', showClose: false}) 
            }
            else { 
              this.$router.push({ path: '/elect' })
            }
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        } else {
          this.$message({message: 'Ошибочные данные.', type: 'error'});
          return false
        }
      })
    },
    querySearch(queryString, cb) {
        let opts = this.options;
        let results = queryString ? opts.filter(this.createFilter(queryString)).sort((a, b) => a.value > b.value ? 1 : -1) : opts;
        // call callback function to return suggestions
        cb(results);
    },
    createFilter(queryString) {
        return (opt) => {
          return (opt.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
    },
    handleSelect(item) {
        //console.log(item);
        this.loginForm.username = item.link
        this.loginForm.password = ''
    }
    /* handleRegister() { 
      this.$router.push({ path: '/register' })
    }, */
  }
}
</script>

<style scoped>

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: #eee;
  overflow: hidden;
}
.login-form {
    position: relative;
    width: 300px;
    max-width: 100%;
    padding: 20px 35px 0;
    margin: 0 auto;
    overflow: hidden;
}
.title-container {
    position: relative;
}
.title {
      font-size: 1.1rem;
      color: rgb(163, 152, 152);
      margin: 5px auto 20px auto;
      text-align: center;
      /*font-weight: bold;*/
}
.butt{
  width:100%;
  margin-left:0px;
  margin-bottom:60px;
}
.inp{
  width:100%;
  margin-bottom:30px;
}
</style>

<style >
.el-input__inner {
  font-size: 0.9rem;
  color:darkblue; 
}
</style>
