<template>
  <div class="login-container">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="profile">
        <template #title><span class="buttxt">Личные данные</span></template>
          <Udata mode="reg" @newudata="newuser" />
      </el-collapse-item>
      <el-collapse-item name="register">
        <template #title><span class="buttxt">Регистрация</span></template>
        <el-form class="login-form" ref='registerForm' :model='newuser_da' :rules='loginRules' 
            auto-complete='on' label-position='left' >
          <el-form-item class="inp" prop='username'>
            <el-input ref='username' v-model='newuser_da.username' placeholder='Назначьте логин' name='username' type='text' 
              tabindex='1' title="7 символов (цифры или буквы)" />
          </el-form-item>
          <el-form-item class="inp" prop='password'>
            <el-input show-password key='passwordType' ref='password' title="от 6 до 20 символов (цифры или буквы)" name='password'   
              v-model='newuser_da.password' type='password' placeholder='Назначьте пароль' tabindex='2' @keyup.enter='handleRegister'/>
          </el-form-item>
          <el-form-item class="inp" prop='grup'>
            <el-input ref='grup' v-model='newuser_da.grup' placeholder='Группа' name='grup' type='text' tabindex='3' title="Группа"/>
          </el-form-item>
          <el-button class="butt" type='primary' @click.prevent='handleRegister'><span class="buttxt">Регистрация</span></el-button>
          <el-button class="butt" type='primary' plain @click.prevent='otmena'> Отмена </el-button>
        </el-form>
      </el-collapse-item>
      <el-collapse-item name="register_list">
        <template #title><span class="buttxt">Регистрация списка из файла</span></template>
        <el-button type="danger" plain @click="del_users" style="margin:3%;width:90%">Удаление всех участников (кроме admin)</el-button>
        <input ref="upload" type="file" accept="text/csv,text/plain" style="margin:5%;width:90%">
        <el-checkbox v-model="is1251" style="margin:3%;width:90%">Отметить, если кодировка файла 1251</el-checkbox>
        <el-button @click="cancelList" style="margin-left:5%;">Отмена</el-button>
        <el-button type="primary" @click="uploadList" style="margin-left:15%;">Загрузить</el-button>
      </el-collapse-item>
    </el-collapse><br/>
  </div>
</template>

<script>
import Udata from '../components/Udata'
import { validUsername } from '@/utils/validate'
import { hostus, genPass } from '../utils/func'
import axios from 'axios'

export default {
  name: 'Register',
  components: { Udata },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!validUsername(value)) {
        callback(new Error('Допустимы буквы, цифры, дефис, подчеркивание; не менее 7 символов'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 7) {
        callback(new Error('В пароле должно быть не менее 7 символов'))
      } else {
        callback(validateUsername(rule, value, callback))
      }
    }
    return {
      newuser_da: {
        username: '',
        password: '',
        grup: '',
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
        grup: [{ required: true, message: 'Должна быть указана группа', trigger: 'blur' }]
      },
      activeName: 'profile',
      logg: '',
      is1251: false,
      redirect: '/login'
    }
  },
  methods: {
    handleRegister() { console.log('this.newuser_da=',this.newuser_da)
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          let title = `Запомните логин: ${this.newuser_da.username} и пароль: ${this.newuser_da.password}`
          this.$confirm(title, 'Подтвердите регистрацию', {confirmButtonText: 'Да', cancelButtonText: 'Отмена', type: 'warning'})
            .then(() => this.$store.dispatch('user/register', this.newuser_da).then((msg) => { //console.log('msg=',msg)
              if (!Number.isInteger(msg)) {
                this.$alert(msg, "Ошибка регистрации", {type: 'error', confirmButtonText: 'Закрыть', showClose: false})
              }
              else { this.$alert(msg, "Успешная регистрация", {type: 'success'}); }
              }).catch(() => { })
            ).catch(() => { })
        } else { // !valid
          this.$message({
            message: 'Ошибочные данные.', type: 'error'
          });
          return false
        }
      })
    },
    otmena() { //console.log('newuser_da=',this.newuser_da)
      this.activeName = 'profile'
      //this.$router.back() // на предыдущий
      //this.$router.push({ path: '/login' })
    },
    create_newuser (newuser_da) {
      let nI = (newuser_da.name_i || '.')[0]
      let nO = (newuser_da.name_o || '.')[0] 
      //let nF = (newuser_da.name_f || '.')[0]
      newuser_da.username = `${newuser_da.name_f} ${nI}.${nO}.${newuser_da.boxes || '*'}` //071024 добавлен boxes
      newuser_da.password = genPass(newuser_da) //`${nF}.${nI}.${nO}.${newuser_da.boxes || '*'}`
      newuser_da.grup = 'user'
      console.log('newuser_da=',newuser_da)
    },
    newuser(da) { //console.log('da=',da)
      this.activeName = 'register'
      this.newuser_da = { name_f: da.name_f, name_i: da.name_i, name_o: da.name_o, boxes: da.boxes} //;debugger
      this.create_newuser(this.newuser_da)
    },
    del_users () {
      let title = `Удалить из базы всех участников (кроме admin) ?`
      this.$confirm(title, 'Подтвердите удаление', {confirmButtonText: 'Да', cancelButtonText: 'Отмена', type: 'error'})
        .then(() => axios.delete(`${hostus}/mysqlus/delete`, {data: { notadmin: 1 }}).then((res) => {
          //console.log('res=',res)
          if (res.data.okmsg) {
            this.$alert(res.data.okmsg, "Успешно", {type: 'success'});
          }
          else this.$alert(res.data.errmsg, "Ошибка ", {type: 'error', confirmButtonText: 'Закрыть', showClose: false})
        }).catch(() => { }) 
        ).catch(() => { })
    },
    cancelList () {
      this.$refs.upload.value = ''
    },
    async uploadList () {
      let file = this.$refs.upload.files[0]
      const isTxt = (file.type === 'text/csv' || file.type === 'text/plain')
      if (isTxt) {
        let th = this
        let reader = new FileReader();
        //reader.readAsText(file);
        var encod = (th.is1251 ? "windows-1251" : "")
        reader.readAsText(file, encod);
        reader.onload = async function() {
          let ar = (reader.result).split('\n') //.split('\r\n')
          console.log('ar=',ar)
          let newuser_da = {}, klreg = 0, klerr = 0, klupd = 0
          for (let el of ar) {
            let ob = el.match(/([а-яА-ЯёЁ-]+|\d+)/g)
            if (ob) {
              //console.log('ob=',ob)
              newuser_da.name_f = ob[1]
              newuser_da.name_i = ob[2]
              newuser_da.name_o = ob[3]
              newuser_da.boxes = ob[ob.length - 1]
              th.create_newuser(newuser_da)
              //console.log('newuser_da=',newuser_da)
              let reg = await th.$store.dispatch('user/register', newuser_da); //console.log('reg=',reg)
              if (Number.isInteger(reg)) { 
                th.logg += `${newuser_da.username}  ||  ${newuser_da.password} \n`
                klreg += 1 
              }
              else { 
                th.logg += `${reg} ! ${newuser_da.username}  ||  ${newuser_da.password} \n`
                if (reg.search('изменение') >= 0) klupd += 1
                else klerr += 1
              } 
            }
          } 
          console.log(th.logg, klerr,klreg)
          //if (klreg > 0) th.$alert("Зарегистрировано: " + klreg, "Успешно", {type: 'success'});
          th.$alert(`<strong>Зарегистрировано: ${klreg};<br> Обновлено: ${klupd}<br>Oтклонено: ${klerr}</strong>` , "Результат", {type: 'info', dangerouslyUseHTMLString: true});
        };
        reader.onerror = function() { this.$message.error(reader.error); }
      }
      else {
        this.$message.error('Расширение файла должно быть csv или txt')
      }    
    }
 
  }
}
</script>

<style scoped>
.login-container {
    position: relative;
    width: 400px;
    max-width: 100%;
    padding: 10px 15px 0;
    margin: 0 auto;
  background-color: rgb(228, 237, 241);
  overflow: hidden;
}
.login-form {
    position: relative;
    width: 300px;
    max-width: 100%;
    padding: 10px 15px 0;
    margin: 0 auto;
    overflow: hidden;
}
.title-container {
    position: relative;
}
.title {
      font-size: 16px;
      color: rgb(163, 152, 152);
      margin: 5px 5px 5px auto;
      text-align: center;
      /*font-weight: bold;*/
}
.butt{
  width:100%;
  margin-left:0px;
  margin-bottom:60px;
}
.buttxt {
  font-size: 1rem;
  margin-left:30px;
}
.inp{
  width:100%;
  margin-bottom:30px;
}
.el-input,
.el-input__inner {
 font-size: 1.0rem; 
}
</style>